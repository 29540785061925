$enable-shadows: true;
$enable-cssgrid: true;

$green-300: #f2f2c5;
$green-400: #f7f6cc;
$green-500: #ccc918;
$green-600: #bdba31;

$yellow-300: #fffae5;
$yellow-350: #fff1bf;
$yellow-400: #f5eed0;
$yellow-500: #ffcc00;
$yellow-600: #ffca05;
$yellow-700: #bbb494;

$blue-200: #eaf6fe;
$blue-400: #1fa9ff;
$blue-500: #1577b3;

$red-100: #ffd2d3;
$red-200: #f2d0d0;
$red-500: #ff1214;
$red-600: #b10e0f;

$pink: #da00c4;

$pure-black: #000000;
$black: #353535;
$white: #ffffff;
$red: $red-500;
$blue: $blue-500;
$yellow: $yellow-500;
$green: $green-500;

$gray-050: #f7f7f7;
$gray-100: #f4f4f4;
$gray-150: #f0f0f0;
$gray-180: #ececec;
$gray-200: #cccccc;
$gray-300: #cecece;
$gray-450: #a1a1a1;
$gray-500: #959595;
$gray-600: #999999;

$primary: $yellow;
$success: $green-500;
$danger: $red-500;
$light: $white;
$error: $red-600;
$ok: $green-600;
$done: $gray-600;
$info: $blue-400;
$warning: $yellow-600;

$theme-colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "error": $error,
  "ok": $ok,
  "done": $done,
);

$link-color: $blue;
$link-hover-color: $black;
$link-color-disabled: $gray-500;

$container-padding-x: 2rem;

$font-family-sans-serif: "Lato", sans-serif;
$headings-font-family: "Montserrat", sans-serif;

$font-size-base: 1rem;
$font-size-sm: 0.875rem;
$font-size-sm-very: 0.625rem;

$h1-font-size: 3.5rem;
$h1-font-size-breakpoint-xs: 2rem;
$h2-font-size: 1.5rem;
$h3-font-size: 1.25rem;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base;
$h6-font-size: 0.75rem;

$headings-font-weight: 600;
$base-font-weight: 400;

$body-bg: $white;

$btn-box-shadow-base: 0.0625rem 0.0625rem 0.375rem;

$container-padding-x: 2rem;

$border-radius-sm: 0.125rem;
$border-radius-md: 0.5rem;

$input-padding-y: 0.53rem;
$input-padding-x: 0.75rem;
$input-font-size: 0.875rem;
$input-focus-border-color: $blue;
$input-focus-box-shadow: inset 0px 0px 0.625rem 0.125rem
  rgba(21, 119, 179, 0.16);
$input-placeholder-color: $gray-500;
$input-border-color: $gray-500;

$input-padding-y-lg: 0.69rem;
$input-padding-x-lg: 0.75rem;
$input-font-size-lg: 1rem;

$input-btn-padding-y: 0.4375rem;
$input-btn-padding-x: 0.1475rem;

$input-btn-padding-y-sm: 0.47rem;
$input-btn-padding-x-sm: 1rem;

$btn-box-shadow: 0.0625rem 0.0625rem 0.375rem rgba(0, 0, 0, 0.15);
$btn-hover-bg-tint-amount: 34%;
$btn-border-width: 0;
$btn-active-bg-tint-amount: 0%;
$btn-active-bg-shade-amount: 30%;
$btn-active-box-shadow: $btn-box-shadow-base rgba(0, 0, 0, 0.15);
$btn-focus-width: 0;

$btn-padding-y: 0.47rem;
$btn-padding-x: 1.21rem;

$btn-padding-y-sm: 0.35rem;
$btn-padding-x-sm: 1rem;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1.6875rem;
$input-btn-font-size-lg: 1rem;

$nav-link-color: $white;
$nav-link-hover-color: $primary;

$nav-tabs-border-width: 0;
$nav-tabs-border-radius: $border-radius-sm;

$nav-link-tab-color: $black;
$nav-link-tab-hover-color: $black;
$nav-link-tab-border-width: 0;
$nav-link-tab-font-size: $font-size-sm;
$nav-link-tab-bg: $yellow-400;
$nav-link-tab-bg-active: $white;
$nav-link-tab-padding-x: 1rem;
$nav-link-tab-padding-y: 0.875rem;

$navbar-nav-link-padding-x: 1rem;

$navbar-nav-link-control-padding-y: 0.25rem;
$navbar-nav-link-control-padding-x: 0.6875rem;
$navbar-nav-link-control-padding-y-breakpoint-lg: 0.25rem;
$navbar-nav-link-control-padding-x-breakpoint-lg: 0.75rem;
$navbar-nav-link-control-font-size: 1.5rem;
$navbar-nav-link-control-color: rgba($white, 0.75);
$navbar-nav-link-control-hover-color: $yellow;
$navbar-color: $white;
$navbar-hover-color: $primary;
$navbar-active-color: $primary;

$navbar-brand-padding-y: 0.25rem;
$navbar-brand-margin-end: 0.5rem;

$navbar-toggler-border-radius: 0;
$navbar-toggler-padding-y: 1rem;
$navbar-toggler-padding-x: 1rem;
$navbar-dark-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg: none;

$border-radius-pill: 50%;
$badge-padding-y: 0.1rem;
$badge-padding-x: 0.1rem;

$col-aside-lg: 17rem;
$col-aside-xl: 22.375rem;

$table-border-width: 0;

$card-border-width: 0;
$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-cap-bg: $white;
$card-cap-padding-y: 1.5rem;
$card-cap-padding-x: 1.5rem;
