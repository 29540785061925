.btn {
  font-weight: 600;
  font-family: $headings-font-family;

  .icon {
    vertical-align: middle;
  }
}

.btn:not(.btn-link) {
  &:disabled,
  &.disabled {
    background-color: $gray-200;
    opacity: 1;
    color: $gray-500;
  }
}

.btn-outline-dark:not(.btn-link) {
  @include button-outline-variant($black, $color-hover: $black, $active-background: lighten($yellow-500, 46.7%));
  background-color: $white;
  box-shadow: $btn-box-shadow;
  &:active {
    box-shadow: $btn-active-box-shadow;
  }

  &:focus {
    box-shadow: $btn-box-shadow;
  }

  &:active,
  &.active,
  &.dropdown-toggle.show {
    background-color: $white;
  }

  &:disabled,
  &.disabled {
    background-color: $gray-100;
    opacity: 0.5;
    color: $gray-500;
  }
}

.btn-link {
  &:active,
  &.active,
  &.dropdown-toggle.show {
    box-shadow: none;
  }

  &:focus {
    box-shadow: none;
    color: $link-hover-color;
  }
  
  &:active:focus {
    box-shadow: none;
  }
}

.btn-sm:not(.btn-link) {
  box-shadow: $btn-box-shadow-base rgba($pure-black, 0.15);
}

.btn-group-sm > .btn-sm:not(.btn-link) {
  box-shadow: none;
}

.icon-button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  line-height: 24px;
}

.btn-wide {
  @include media-breakpoint-up(lg) {
    padding-left: rem(47);
    padding-right: rem(47);
  }
}
