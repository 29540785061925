@import "../bootstrap-override/common";
@import "/src/assets/fonts/iconfont/icons";

.form-check {
  display: flex;
  align-items: center;
  gap: 1rem;
  @include media-breakpoint-down(md) {
    gap: rem(6);
  }
}

input.form-check-input[type='checkbox'] {
  width: rem(32);
  height: rem(32);
  background: $white;
  border: 1px solid $gray-500;
  cursor: pointer;
  margin-left: -1.5rem;
  margin-top: 0;
  @include media-breakpoint-down(md) {
    width: rem(24);
    height: rem(24);
  }
}

input.form-check-input[type='checkbox']:checked {
  border-color: $gray-500;

  &::before {
    content: map-get($icons-map, 'check');
    font-family: icons !important;
    color: $blue-500;
    display: flex;
    justify-content: center;
    font-size: 20px;
    @include media-breakpoint-down(md) {
      font-size: rem(15);
    }
  }
}

input.form-check-input[type='checkbox']:focus {
  border-color: $gray-500;
  box-shadow: none;
}

input.form-check-input[type='checkbox']:active {
  filter: none;
}

.form-check-label {
  position: relative;
  margin-bottom: 0;
}
