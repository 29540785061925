@import '../../../styles/bootstrap-override/common.scss';

.container {
  width: 100%;
  background-color: $white;

  position: relative;
  display: flex;
  align-items: center;
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  max-height: 300px;
  overflow-y: auto;

  margin: 0.5rem 0;
  border-radius: 0.5rem;
  border: 1px solid $input-border-color;

  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  box-sizing: border-box;
  background-color: $white;
}

.optionList {
  margin: 0.5rem 0;
}

.option {
  padding: 0.25rem 0.5rem;

  &:hover {
    background-color: $gray-100;
  }
}

.optionNullName {
  color: $gray-600;
  font-style: italic;
}

.input {
  width: 100%;
  padding: 0.5rem $input-padding-x;
  min-width: 3rem;

  &:focus {
    outline: none;
  }
}

.chevron {
  position: absolute;
  right: 0.75rem;
  font-size: 0.66rem;
}

.invalid {
  padding-right: 3.8rem !important;
  background-position: right 1.9rem center !important;
}
