$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("./icons.eot?7bc76ca6498394563ea9c54722fd7a38#iefix") format("embedded-opentype"),
url("./icons.woff2?7bc76ca6498394563ea9c54722fd7a38") format("woff2"),
url("./icons.woff?7bc76ca6498394563ea9c54722fd7a38") format("woff");
}

span[class^="icon-"], span[class*=" icon-"] {
    font-family: icons !important;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    vertical-align: baseline;
}

$icons-map: (
    "angle-down": "\f101",
    "angle-right": "\f102",
    "archive": "\f103",
    "arrow-down-left": "\f104",
    "arrow-down-right": "\f105",
    "arrow-down": "\f106",
    "arrow-left-up": "\f107",
    "arrow-left": "\f108",
    "arrow-right-rounded": "\f109",
    "arrow-right": "\f10a",
    "arrow-up-right": "\f10b",
    "arrow-up": "\f10c",
    "battery-empty": "\f10d",
    "battery-full": "\f10e",
    "battery-half": "\f10f",
    "battery-quarter": "\f110",
    "battery-three-quarters": "\f111",
    "battery": "\f112",
    "bell": "\f113",
    "blades": "\f114",
    "broadcast-tower": "\f115",
    "broadcast": "\f116",
    "bug": "\f117",
    "calendar-alt": "\f118",
    "chart-column": "\f119",
    "check-circle-outline": "\f11a",
    "check-circle": "\f11b",
    "check": "\f11c",
    "chevron-left": "\f11d",
    "chevron-right": "\f11e",
    "circle-xmark": "\f11f",
    "clock": "\f120",
    "clone": "\f121",
    "code-branch": "\f122",
    "comment": "\f123",
    "compas": "\f124",
    "crosshairs-location": "\f125",
    "crosshairs": "\f126",
    "download": "\f127",
    "engine": "\f128",
    "export": "\f129",
    "fuel": "\f12a",
    "grip-dots": "\f12b",
    "home": "\f12c",
    "input-text-light": "\f12d",
    "joystick": "\f12e",
    "list-timeline": "\f12f",
    "location": "\f130",
    "logout": "\f131",
    "map-layers": "\f132",
    "map-pin": "\f133",
    "messages": "\f134",
    "minus-circle": "\f135",
    "minus": "\f136",
    "mode": "\f137",
    "mower": "\f138",
    "pause": "\f139",
    "pen": "\f13a",
    "pending": "\f13b",
    "play-circle": "\f13c",
    "play": "\f13d",
    "plus-circle": "\f13e",
    "plus-square": "\f13f",
    "plus": "\f140",
    "question-circle": "\f141",
    "reboot": "\f142",
    "reload-arrow": "\f143",
    "remote-control": "\f144",
    "robot": "\f145",
    "route": "\f146",
    "router": "\f147",
    "ruler-combined": "\f148",
    "ruler": "\f149",
    "satellite-pin": "\f14a",
    "satellite": "\f14b",
    "search": "\f14c",
    "server": "\f14d",
    "share": "\f14e",
    "signal-fair": "\f14f",
    "signal-good": "\f150",
    "signal-strong": "\f151",
    "signal-weak": "\f152",
    "signal": "\f153",
    "stop-circle": "\f154",
    "stop": "\f155",
    "target": "\f156",
    "terrain-marker": "\f157",
    "terrain": "\f158",
    "times": "\f159",
    "toggle-off": "\f15a",
    "toggle-on": "\f15b",
    "toolbox": "\f15c",
    "trash-undo": "\f15d",
    "trash": "\f15e",
    "user-circle": "\f15f",
    "user": "\f160",
);

.icon-angle-down::before {
    content: map-get($icons-map, "angle-down");
}
.icon-angle-right::before {
    content: map-get($icons-map, "angle-right");
}
.icon-archive::before {
    content: map-get($icons-map, "archive");
}
.icon-arrow-down-left::before {
    content: map-get($icons-map, "arrow-down-left");
}
.icon-arrow-down-right::before {
    content: map-get($icons-map, "arrow-down-right");
}
.icon-arrow-down::before {
    content: map-get($icons-map, "arrow-down");
}
.icon-arrow-left-up::before {
    content: map-get($icons-map, "arrow-left-up");
}
.icon-arrow-left::before {
    content: map-get($icons-map, "arrow-left");
}
.icon-arrow-right-rounded::before {
    content: map-get($icons-map, "arrow-right-rounded");
}
.icon-arrow-right::before {
    content: map-get($icons-map, "arrow-right");
}
.icon-arrow-up-right::before {
    content: map-get($icons-map, "arrow-up-right");
}
.icon-arrow-up::before {
    content: map-get($icons-map, "arrow-up");
}
.icon-battery-empty::before {
    content: map-get($icons-map, "battery-empty");
}
.icon-battery-full::before {
    content: map-get($icons-map, "battery-full");
}
.icon-battery-half::before {
    content: map-get($icons-map, "battery-half");
}
.icon-battery-quarter::before {
    content: map-get($icons-map, "battery-quarter");
}
.icon-battery-three-quarters::before {
    content: map-get($icons-map, "battery-three-quarters");
}
.icon-battery::before {
    content: map-get($icons-map, "battery");
}
.icon-bell::before {
    content: map-get($icons-map, "bell");
}
.icon-blades::before {
    content: map-get($icons-map, "blades");
}
.icon-broadcast-tower::before {
    content: map-get($icons-map, "broadcast-tower");
}
.icon-broadcast::before {
    content: map-get($icons-map, "broadcast");
}
.icon-bug::before {
    content: map-get($icons-map, "bug");
}
.icon-calendar-alt::before {
    content: map-get($icons-map, "calendar-alt");
}
.icon-chart-column::before {
    content: map-get($icons-map, "chart-column");
}
.icon-check-circle-outline::before {
    content: map-get($icons-map, "check-circle-outline");
}
.icon-check-circle::before {
    content: map-get($icons-map, "check-circle");
}
.icon-check::before {
    content: map-get($icons-map, "check");
}
.icon-chevron-left::before {
    content: map-get($icons-map, "chevron-left");
}
.icon-chevron-right::before {
    content: map-get($icons-map, "chevron-right");
}
.icon-circle-xmark::before {
    content: map-get($icons-map, "circle-xmark");
}
.icon-clock::before {
    content: map-get($icons-map, "clock");
}
.icon-clone::before {
    content: map-get($icons-map, "clone");
}
.icon-code-branch::before {
    content: map-get($icons-map, "code-branch");
}
.icon-comment::before {
    content: map-get($icons-map, "comment");
}
.icon-compas::before {
    content: map-get($icons-map, "compas");
}
.icon-crosshairs-location::before {
    content: map-get($icons-map, "crosshairs-location");
}
.icon-crosshairs::before {
    content: map-get($icons-map, "crosshairs");
}
.icon-download::before {
    content: map-get($icons-map, "download");
}
.icon-engine::before {
    content: map-get($icons-map, "engine");
}
.icon-export::before {
    content: map-get($icons-map, "export");
}
.icon-fuel::before {
    content: map-get($icons-map, "fuel");
}
.icon-grip-dots::before {
    content: map-get($icons-map, "grip-dots");
}
.icon-home::before {
    content: map-get($icons-map, "home");
}
.icon-input-text-light::before {
    content: map-get($icons-map, "input-text-light");
}
.icon-joystick::before {
    content: map-get($icons-map, "joystick");
}
.icon-list-timeline::before {
    content: map-get($icons-map, "list-timeline");
}
.icon-location::before {
    content: map-get($icons-map, "location");
}
.icon-logout::before {
    content: map-get($icons-map, "logout");
}
.icon-map-layers::before {
    content: map-get($icons-map, "map-layers");
}
.icon-map-pin::before {
    content: map-get($icons-map, "map-pin");
}
.icon-messages::before {
    content: map-get($icons-map, "messages");
}
.icon-minus-circle::before {
    content: map-get($icons-map, "minus-circle");
}
.icon-minus::before {
    content: map-get($icons-map, "minus");
}
.icon-mode::before {
    content: map-get($icons-map, "mode");
}
.icon-mower::before {
    content: map-get($icons-map, "mower");
}
.icon-pause::before {
    content: map-get($icons-map, "pause");
}
.icon-pen::before {
    content: map-get($icons-map, "pen");
}
.icon-pending::before {
    content: map-get($icons-map, "pending");
}
.icon-play-circle::before {
    content: map-get($icons-map, "play-circle");
}
.icon-play::before {
    content: map-get($icons-map, "play");
}
.icon-plus-circle::before {
    content: map-get($icons-map, "plus-circle");
}
.icon-plus-square::before {
    content: map-get($icons-map, "plus-square");
}
.icon-plus::before {
    content: map-get($icons-map, "plus");
}
.icon-question-circle::before {
    content: map-get($icons-map, "question-circle");
}
.icon-reboot::before {
    content: map-get($icons-map, "reboot");
}
.icon-reload-arrow::before {
    content: map-get($icons-map, "reload-arrow");
}
.icon-remote-control::before {
    content: map-get($icons-map, "remote-control");
}
.icon-robot::before {
    content: map-get($icons-map, "robot");
}
.icon-route::before {
    content: map-get($icons-map, "route");
}
.icon-router::before {
    content: map-get($icons-map, "router");
}
.icon-ruler-combined::before {
    content: map-get($icons-map, "ruler-combined");
}
.icon-ruler::before {
    content: map-get($icons-map, "ruler");
}
.icon-satellite-pin::before {
    content: map-get($icons-map, "satellite-pin");
}
.icon-satellite::before {
    content: map-get($icons-map, "satellite");
}
.icon-search::before {
    content: map-get($icons-map, "search");
}
.icon-server::before {
    content: map-get($icons-map, "server");
}
.icon-share::before {
    content: map-get($icons-map, "share");
}
.icon-signal-fair::before {
    content: map-get($icons-map, "signal-fair");
}
.icon-signal-good::before {
    content: map-get($icons-map, "signal-good");
}
.icon-signal-strong::before {
    content: map-get($icons-map, "signal-strong");
}
.icon-signal-weak::before {
    content: map-get($icons-map, "signal-weak");
}
.icon-signal::before {
    content: map-get($icons-map, "signal");
}
.icon-stop-circle::before {
    content: map-get($icons-map, "stop-circle");
}
.icon-stop::before {
    content: map-get($icons-map, "stop");
}
.icon-target::before {
    content: map-get($icons-map, "target");
}
.icon-terrain-marker::before {
    content: map-get($icons-map, "terrain-marker");
}
.icon-terrain::before {
    content: map-get($icons-map, "terrain");
}
.icon-times::before {
    content: map-get($icons-map, "times");
}
.icon-toggle-off::before {
    content: map-get($icons-map, "toggle-off");
}
.icon-toggle-on::before {
    content: map-get($icons-map, "toggle-on");
}
.icon-toolbox::before {
    content: map-get($icons-map, "toolbox");
}
.icon-trash-undo::before {
    content: map-get($icons-map, "trash-undo");
}
.icon-trash::before {
    content: map-get($icons-map, "trash");
}
.icon-user-circle::before {
    content: map-get($icons-map, "user-circle");
}
.icon-user::before {
    content: map-get($icons-map, "user");
}
