@import "../../../styles/bootstrap-override/common.scss";

.h1 {
  margin-bottom: 0.428em;
  font-size: $h1-font-size-breakpoint-xs;
  @include media-breakpoint-up(sm) {
    font-size: $h1-font-size;
  }
}

.h2 {
  font-size: $h2-font-size;
}

.h3 {
  font-size: $h3-font-size;
}

.h4 {
  margin-bottom: 1em;
  font-size: $h4-font-size;
}

.h5 {
  margin-bottom: 0.75em;
  font-size: $h4-font-size;
  font-weight: 500;
}

.h6 {
  margin-bottom: 0.75em;
  font-size: $h6-font-size;
  font-weight: $base-font-weight;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.line-through {
  display: flex;
  align-items: center;
  gap: em(8);
  font-size: rem(12);
  color: rgba(#B1A87F, 0.5);
  margin-top: em(16);
  margin-bottom: em(16);
  text-align: center;
  font-weight: $base-font-weight;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  &::before,
  &::after {
    flex: 1;
    content: "";
    display: inline-block;
    height: 1px;
    background-color: $yellow-400;
  }
}
