.gm-style > div {
  border: none !important;
}

.gm-style-iw {
  margin-bottom: 1rem;
}

.gm-style-iw {
  padding: 1rem !important;
  padding-right: 2rem !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

.gm-ui-hover-effect {
  right: 16px !important;
  top: 16px !important;
  width: 22px !important;
  height: 22px !important;
  margin: 0 !important;
  span {
        width: 22px !important;
        height: 22px !important;
        margin: 0 !important;
      }
}

.gm-style .gm-style-iw-a {
  transform: translateY(-3.125rem);
}

@media screen and (max-width: 576px) {
  .gm-style-iw {
    padding-left: 0.5rem !important;
    max-width: 280px !important;
  }
}