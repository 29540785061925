.card {
  font-size: $font-size-sm;
  border: 0;
}

.card-body {
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
}

.card-footer {
  position: relative;
  display: flex;
  column-gap: 0.5rem;
  @include media-breakpoint-down(lg) {
    padding: 1rem;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: $card-cap-padding-x;
    right: $card-cap-padding-x;
    height: 1px;
    background-color: $yellow-400;
    @include media-breakpoint-down(lg) {
      left: 1rem;
      right: 1rem;
    }
  }
}
