@import "./styles/bootstrap-override/common.scss";

.internetHealthBar {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  color: white;
  font-weight: bold;
  z-index: 1;
  border: 1px red;
}
