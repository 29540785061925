@import "../../../styles/bootstrap-override/common.scss";

div.modal {
  position: relative;
  top: 0 !important;
}

div.overlay {
  background-color: $black;
}

div.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

span.icon {
  font-size: 9rem;
  color: $white;
  opacity: 0.25;
}

.message {
  font-weight: $headings-font-weight;
  color: $white;
  text-align: center;
}

div.footer {
  border: none;
  padding-bottom: calc(env(safe-area-inset-bottom) + 1.5rem);
}

button.allowButton {
  font-size: $font-size-sm;
  padding: 0.5rem 2rem;
  margin: 0 auto;
}

button.goNextButton {
  color: $white;
  font-size: $font-size-sm;
  position: absolute;
  margin: 0;
  right: 1rem;

  @include media-breakpoint-down(sm) {
    right: 0.5rem;
  }

  &:hover {
    color: $white;
  }

  &:focus {
    color: $white;
  }
}

