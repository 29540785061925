// Custom variables
@import "./variables.scss";

// Bootstrap
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "../utils/functions.scss";

:root {
  --bs-rows: 1;
  --bs-columns: 12;
  --bs-gap: 1.5rem;
  --bs-gutter-x: 1.5rem;
  --bs-gutter-container-x: 2rem;
  @include media-breakpoint-down(xl) {
    --bs-gutter-x: 1rem;
    --bs-gutter-container-x: 1rem;
  }
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1054px,
  xl: 1200px,
  xxl: 1400px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
