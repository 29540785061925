.table--simple {
  width: auto;
  margin-bottom: 0;
  td {
    padding: 0.25em;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
}
