@import "../../../styles/bootstrap-override/common.scss";

$spinner-size: 2rem;

.wrapper {
  display: inline;
  color: $primary;
  text-align: center;
}

.wrapper-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  background: rgba($modal-backdrop-bg, 0.1);
  z-index: 10;
}

.wrapper-centered {
  position: absolute;
  top: calc(50% - $spinner-size / 2);
  left: calc(50% - $spinner-size / 2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
